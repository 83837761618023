// const options = {
// 	threshold: 0.2,
// 	selector: ["data-video"],
// };

// const videos = document.querySelectorAll("[data-video]");

// const videoObserver = new IntersectionObserver((entries, observer) => {
// 	entries.forEach((entry) => {
// 		const playVideoPromise = entry.target.play();
// 		if (!entry.isIntersecting) {
// 			playVideoPromise.then((_) => {
// 				entry.target.pause();
// 			});
// 		}
// 	});
// }, options);

// videos?.forEach((video) => videoObserver.observe(video));
