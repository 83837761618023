const popupButtons = document.querySelectorAll(".video-btn");
const popup = document.querySelector(".Popup");
const popupContent = popup?.querySelector(".Popup__content");
const closePopupButton = popup?.querySelector(".Popup__close");

const togglePopupVideo = (ev) => {
	ev.preventDefault();
	const frame = ev.currentTarget.getAttribute("data-frame");

	popupContent.innerHTML = frame;

	togglePopupActiveClass();
};

const togglePopupActiveClass = () => {
	popup.classList.toggle("Popup--active");
};

closePopupButton?.addEventListener("click", togglePopupActiveClass);

popupButtons?.forEach((button) =>
	button.addEventListener("click", togglePopupVideo)
);
