/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

export const toggleMenu = () => {
	const hamburgerBtn = document.querySelector(".Header__hamburgerWrapper");
	const menu = document.querySelector(".Navigation");
	const headerOverlay = document.querySelector(".Header__overlay");
	const header = document.querySelector(".Header");

	hamburgerBtn.addEventListener("click", () => {
		hamburgerBtn.classList.toggle("Hamburger--open");
		menu.classList.toggle("active");
		headerOverlay.classList.toggle("visible");
		header.classList.toggle("Header--active");
	});
};

export const closeMenu = () => {};

export const addSticky = () => {
	let toggle = document.getElementById("toggle"),
		header = document.getElementById("masthead");

	toggle &&
		toggle.addEventListener("click", () => {
			header.classList.add("sticky");
		});
};
