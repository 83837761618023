import Swiper from "swiper";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  Controller,
} from "swiper/core";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade, Controller]);

const imageSwiperCopy = new Swiper(".slider-copy", {
  loop: true,
  // autoHeight: true,
  slidesPerView: 1,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

const contentSwiperCopy = new Swiper(".Slider__containerCopy", {
  loop: true,
  navigation: {
    prevEl: ".Slider__arrow--leftCopy",
    nextEl: ".Slider__arrow--rightCopy",
  },
});

imageSwiperCopy.controller.control = contentSwiperCopy;
contentSwiperCopy.controller.control = imageSwiperCopy;
