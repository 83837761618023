import FloatLabels from "./forms/float-labels";
import { toggleMenu } from "./navigation";
import "./Slider";
import "./SliderCopy";
import "./SinglePostSlider";
import "./SearchPopup";
import "./PostVideo";
import "./PopupVideo";

window.addEventListener("DOMContentLoaded", () => {
  new FloatLabels();

  toggleMenu();
});
