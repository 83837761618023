const button = document.querySelector(".Topbar__search");
const popup = document.querySelector(".SearchPopup");
const closeButton = document.querySelector(".SearchPopup__close");

const ACTIVE_CLASS = "SearchPopup--active";

const togglePopup = () => {
	popup.classList.toggle(ACTIVE_CLASS);
};

const hidePopup = () => {
	popup.classList.remove(ACTIVE_CLASS);
};

closeButton.addEventListener("click", hidePopup);
button.addEventListener("click", togglePopup);
