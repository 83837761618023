import Swiper from "swiper";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  Controller,
} from "swiper/core";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade, Controller]);

const imageSwiper = new Swiper(".slider-images", {
  loop: true,
  // autoHeight: true,
  slidesPerView: 1,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

const contentSwiper = new Swiper(".Slider__container", {
  loop: true,
  navigation: {
    prevEl: ".Slider__arrow--left",
    nextEl: ".Slider__arrow--right",
  },
});

imageSwiper.controller.control = contentSwiper;
contentSwiper.controller.control = imageSwiper;
