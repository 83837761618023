import Swiper from "swiper";
import SwiperCore, {
	Navigation,
	Pagination,
	Autoplay,
	Thumbs,
} from "swiper/core";

SwiperCore.use([Navigation, Pagination, Autoplay, Thumbs]);

/** Thumbnail Slider */
const swiper = new Swiper(".SingleArticle__galleryThumbnails", {
	spaceBetween: 10,
	slidesPerView: 2,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	breakpoints: {
		768: {
			slidesPerView: 5,
		},
	},
});

/** Main Slider */
const swiper2 = new Swiper(".SingleArticle__slider", {
	loop: true,
	spaceBetween: 10,
	navigation: {
		nextEl: ".SingleArticle__sliderBtn--right",
		prevEl: ".SingleArticle__sliderBtn--left",
	},
	thumbs: {
		swiper: swiper,
	},
});
